import React from 'react';
import { useState } from "react";
import { useFormik } from 'formik';

const validate = (values) => {
    const errors = {}

    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    return errors
}

function SubscriptionForm() {

    const [checkboxValues, setCheckboxValues] = useState({
        geneva: false,
        lausanne: false,
        zurich: false,
        basel: false,
        lugano: false,
    });
    function handleCheckboxChange(event) {
        const { name, checked } = event.target;
        setCheckboxValues({ ...checkboxValues, [name]: checked });
    }


    const [ isAlertVisible, setIsAlertVisible ] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate,
        onSubmit: async (values) => {
            let formData = new FormData();
            formData.append('email', values.email);

            let cities = [];
            for (const [name, checked] of Object.entries(checkboxValues)) {
                if (checked) {
                    cities.push(name);
                }
            }
            if (cities.length === 0) {
                for (const [name, checked] of Object.entries(checkboxValues)) {
                    if (!checked) {
                        cities.push(name);
                    }
                }
            }
            formData.append('cities', cities);

            // http://localhost:8080/api/email
            fetch('https://artswiss.club/api/email', {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
            setIsAlertVisible(true);
            setTimeout(() => {
                setIsAlertVisible(false);
            }, 2500);
            await new Promise(
                resolve => setTimeout(resolve, 3000)
            );
            window.location.reload(true);
        },
    })
    return (
        <form id="form-subscription" onSubmit={formik.handleSubmit}>
            <div className="input">
                <input type="email" className="email" placeholder="example@example.com" name="email" id="email"
                       onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}/>
            </div>
            {formik.touched.email && formik.errors.email && (
                <div className="error">{formik.errors.email}</div>
            )}
            <div id="cities">
                <label>Select cities if you want only specific geography:</label>
            </div>
            <div className="check">

                <input type="checkbox" name="geneva" id="geneva"
                       checked={checkboxValues.geneva} onChange={handleCheckboxChange}/>
                <label htmlFor="geneva">Geneva</label>
            </div>
            <div className="check">
                <input type="checkbox" name="lausanne" id="lausanne"
                       checked={checkboxValues.lausanne} onChange={handleCheckboxChange}/>
                <label htmlFor="lausanne">Lausanne</label>
            </div>
            <div className="check">
                <input type="checkbox" name="zurich" id="zurich"
                       checked={checkboxValues.zurich} onChange={handleCheckboxChange}/>
                <label htmlFor="zurich">Zurich</label>
            </div>
            <div className="check">
                <input type="checkbox" name="basel" id="basel"
                       checked={checkboxValues.basel} onChange={handleCheckboxChange}/>
                <label htmlFor="basel">Basel</label>
            </div>
            <div className="check">
                <input type="checkbox" name="lugano" id="lugano"
                       checked={checkboxValues.lugano} onChange={handleCheckboxChange}/>
                <label htmlFor="lugano">Lugano</label>
            </div>
            <div className="button">
                <button type="submit" className="submit">SUBSCRIBE</button>
            </div>
            {isAlertVisible && <div className='alert-container'>
                <div className='alert-inner'>{formik.initialValues.email} was added to distribution list. Check your mail box!</div>
                </div>
            }
        </form>
    );
}

export default SubscriptionForm;