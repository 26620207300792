import logo from './logo.png';
import React, { useState } from 'react';
import './App.css';
import TypingComponent from "./TypingComponent";
import SubscriptionForm from "./SubscriptionForm";

function Main() {
    const text = ["I'm bot that monitors temporary art exhibitions across main galleries in Switzerland. \n",
        "Sign up to distribution list and I'll send you current schedule of exhibitions ",
        "and then notify you about new and just started exhibitions on weekly bases."];
    const TypingComponentStyles = {}
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <div className="App-desc">
                <p>Hello, Human.</p>
                <TypingComponent
                    classString="typewriter"
                    text1={text}
                    typingContentElementId="typewriter1"
                    styles={TypingComponentStyles}
                />
            </div>
            <div>
                <SubscriptionForm />
            </div>
            <div className="telegram">
                <div id="telegram_text_container"><p id="telegram_text">I also exist in a form of </p></div>
                <div id="telegram_link_container"><a id="telegram_link" href="https://t.me/artswiss_bot">TELEGRAM BOT</a></div>
            </div>
            <div className="footer">
                <div className="sources">
                    <ul>
                        <li>Sources:</li>
                        <li>Kunstmuseum Basel</li>
                        <li>Museum Tinguely Basel</li>
                        <li>Fondation Beyeler Basel</li>
                        <li>Kunsthaus Zürich</li>
                        <li>Zentrum Paul Klee Zürich</li>
                        <li>MAMCO musée d'art moderne et contemporain Genève</li>
                        <li>Musée d’art et d'histoire Genève</li>
                        <li>Musée d'ethnographie Genève</li>
                        <li>Plateforme 10 Lausanne</li>
                        <li>Museo d'arte della svizzera Italiana Lugano</li>
                    </ul>
                </div>
                <div className="info">
                    <p>Send suggestions or offer the author a beer: hramutichev.a@gmail.com or<a href="https://t.me/khramutichev">telegram</a> </p>
                </div>
            </div>
        </div>
    );
}

export default Main;