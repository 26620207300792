import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import Unsubscribe from './Unsubscribe';


function App() {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Main/>}/>
          <Route path='/unsubscribe/:email' element={<Unsubscribe/>}/>
        </Routes>
      </Router>
  );
}

export default App;
