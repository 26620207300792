import logo from './logo.png';
import React, { useState } from 'react';
import './App.css';
import { Link, useNavigate, useParams } from 'react-router-dom';

function Unsubscribe(){
    const navigate = useNavigate();
    const { email } = useParams();
    const [ isAlertVisible, setIsAlertVisible ] = React.useState(false);
    // const data = {
    //     email: email,
    // };
    const [mail, setMail] = useState(email);
    const handleChange = (event) => {
        setMail(event.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('email', mail);
        fetch('https://artswiss.club/api/email', {
            method: 'DELETE',
            mode: 'cors',
            body: formData
        })
        setIsAlertVisible(true);
        setTimeout(() => {
            setIsAlertVisible(false);
        }, 1500);
        await new Promise(
            resolve => setTimeout(resolve, 3000)
        );
        navigate('/');
    }
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <div>
                <form id="form-subscription" onSubmit={handleSubmit}>
                    <div className="input">
                        <input type="email" className="email" name="email" id="email"
                               onChange={handleChange} value={mail}/>
                    </div>
                    <div className="button">
                        <button type="submit" className="submit" id="unsubscribe">UNSUBSCRIBE</button>
                    </div>
                    {isAlertVisible && <div className='alert-container'>
                        <div className='alert-inner'>was removed from distribution list</div>
                    </div>
                    }
                </form>
            </div>
        </div>
    );
}

export default Unsubscribe;